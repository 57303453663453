import MarketingPageLayout from "../components/features/Marketing/MarketingPageLayout";
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
import { IRouteType } from "../models/IRouteType";
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import SystemPageLayout from "../components/features/System/SystemPageLayout";
import UserRole from "../components/features/System/UserRole/UserRole";
import AddRolePage from "../components/features/System/UserRole/AddRolePage";
import EditRolePage from "../components/features/System/UserRole/EditRolePage";
import Vouchers from "../components/features/Marketing/CouponDefinitions/Vouchers";
import CreateVoucherPage from "../components/features/Marketing/CouponDefinitions/CreateVoucherPage";
import EditVoucherPage from "../components/features/Marketing/CouponDefinitions/EditVoucherPage";
import MemberGroup from "../components/features/System/MemberGroup/MemberGroups";
import AddMemberGroup from "../components/features/System/MemberGroup/AddMemberGroup";
import EditMemberGroupPage from '../components/features/System/MemberGroup/EditMemberGroup';
import CreateMemberGroupCoupon from "../components/features/Marketing/MemberGroupCoupons/CreateMemberGroupCoupon";
import MemberGroupCouponTable from "../components/features/Marketing/MemberGroupCoupons/MemberGroupCouponTable";
import EditMemberGroupCoupon from '../components/features/Marketing/MemberGroupCoupons/EditMemberGroupCoupon';
import UserCouponTable from "../components/features/Marketing/UserCoupons/UserCouponTable";
import CreateUserCoupon from "../components/features/Marketing/UserCoupons/CreateUserCoupon";
import EditUserCoupon from "../components/features/Marketing/UserCoupons/EditUserCoupon";
import RewardPointTable from "../components/features/RewardPoints/RewardPoints";
import CreateRewardPointPage from "../components/features/RewardPoints/CreateRewardPointPage";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import OrderTable from "../components/features/History/Orders/OrderTable";
import OrderDetailsPage from "../components/features/History/Orders/OrderDetailsPage";
import PaymentTable from "../components/features/History/Payments/PaymentTable";
import PaymentDetailsPage from "../components/features/History/Payments/PaymentDetailsPage";
import UserDetailsPage from "../components/features/System/Users/UserDetailsPage";
import SearchUserTable from "../components/features/System/Users/SearchUserTable";

const appRoutes: IRouteType[] = [
    {
        path: "/Marketing",
        element: <MarketingPageLayout />,
        state: "Marketing",
        sidebarProps: {
            displayText: "Marketing",
            icon: <CampaignOutlinedIcon />
        },
        child: [
            {   
                path: "/Marketing/CouponDefinitions",
                element: <Vouchers />,
                state: "Marketing.Vouchers.Vouchers",
                sidebarProps: {
                    displayText: "Coupon Definitions",
                    icon: <RedeemOutlinedIcon />
                }
            },
            {   
                path: "/Marketing/MemberGroupCoupons",
                element: <MemberGroupCouponTable />,
                state: "Marketing.Vouchers.MemberGroupCouponTable",
                sidebarProps: {
                    displayText: "Member Group Coupons",
                    icon: <RedeemOutlinedIcon />
                }
            },
            {   
                path: "/Marketing/UserCoupons",
                element: <UserCouponTable />,
                state: "Marketing.Vouchers.UserCoupons",
                sidebarProps: {
                    displayText: "User Coupons",
                    icon: <RedeemOutlinedIcon />
                }
            },
            {
                path: "/Marketing/RewardPoint",
                element: <RewardPointTable />,
                state: "Marketing.RewardPoint",
                sidebarProps: {
                    displayText: "Reward Point",
                    icon: <RedeemOutlinedIcon />
                }
            },
            {
                path: "/Marketing/RewardPoint/AddRewardPoint/",
                element: <CreateRewardPointPage />,
                state: "Marketing.RewardPoint.AddRewardPoint"
            },
            {
                path: "/Marketing/Vouchers/EditCouponDefinitions/:id",
                element: <EditVoucherPage />,
                state: "Marketing.Vouchers.EditCouponDefinitions"
            },
            {
                path: "/Marketing/Vouchers/CreateCouponDefinitions/",
                element: <CreateVoucherPage />,
                state: "Marketing.Vouchers.CreateCouponDefinitions"
            },
            {
                path: "/Marketing/Vouchers/CreateMemberGroupCoupons/",
                element: <CreateMemberGroupCoupon />,
                state: "Marketing.Vouchers.CreateMemberGroupCoupons"
            },
            {
                path: "/Marketing/Vouchers/EditMemberGroupCoupons/:id",
                element: <EditMemberGroupCoupon />,
                state: "Marketing.Vouchers.EditMemberGroupCoupon"
            },
            {
                path: "/Marketing/Vouchers/CreateUserCoupons/",
                element: <CreateUserCoupon />,
                state: "Marketing.Vouchers.CreateUserCoupons"
            },
            {
                path: "/Marketing/Vouchers/EditUserCoupons/:id",
                element: <EditUserCoupon />,
                state: "Marketing.Vouchers.EditUserCoupons"
            }
        ]
    },
    {
        path: "/System",
        element: <SystemPageLayout />,
        state: "System",
        sidebarProps: {
            displayText: "System",
            icon: <SettingsIcon />
        },
        child: [
            {
                path: "/System/Roles",
                element: <UserRole />,
                state: "System.Roles",
                sidebarProps: {
                    displayText: "Roles",
                    icon: <PersonIcon />
                }
            },
            {
                path: "/System/Roles/AddRole",
                element: <AddRolePage />,
                state: "System.Roles.AddRole"
            },
            {
                path: "/System/Roles/EditRoles/:id",
                element: <EditRolePage />,
                state: "System.Roles.EditRoles"
            },
            {
                path: "/System/MemberGroups",
                element: <MemberGroup />,
                state: "System.MemberGroups",
                sidebarProps: {
                    displayText: "Member Groups",
                    icon: <PersonIcon />
                }
            },
            {
                path: "/System/MemberGroups/AddMemberGroups",
                element: <AddMemberGroup />,
                state: "System.MemberGroups.AddMemberGroups"
            },
            {
                path: "/System/MemberGroups/EditMemberGroups/:id",
                element: <EditMemberGroupPage />,
                state: "System.MemberGroups.EditMemberGroups"
            }

        ]
    },
    {   
        path: "users",
        element: <SearchUserTable />,
        state: "Users",
        sidebarProps: {
            displayText: "Users",
            icon: <PersonIcon />
        }
        
    },
    {   
        path: "/users/user_details/:id",
        element: <UserDetailsPage />,
        state: "Users.EditUsers"
    },
    {
        path: "orders",
        element: <OrderTable />,
        state: "Orders",
        sidebarProps: {
            displayText: "Orders",
            icon: <ShoppingCartIcon />
        },
    },
    {
        path: "payments",
        element: <PaymentTable />,
        state: "Payments",
        sidebarProps: {
            displayText: "Payments",
            icon: <PaymentIcon />
        },
    },
    {
        path: "order_details/:orderId",
        element: <OrderDetailsPage />,
        state: "OrderDetails",
    },
    {
        path: "payment_details/:paymentId",
        element: <PaymentDetailsPage />,
        state: "PaymentDetails",
    },
];

export default appRoutes;