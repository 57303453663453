import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card } from '@mui/material';
import dayjs from 'dayjs';
import { getStoreCreditTransactionByUserIdAPI } from '../../../../../api/StoreCreditAPI';
import { StoreCreditResponse, IStoreCreditTransaction } from '../../../../../models/IStoreCredit';
import { PATH_NAME } from '../../../../../configs/pathName';
import useSWR from 'swr';
import { getUserWalletInfo } from '../../../../../api/UserWalletInfoAPI';

interface StoreCreditTransactionProps {
  userId: number;
}

const StoreCreditTransaction: React.FC<StoreCreditTransactionProps> = ({ userId }) => {
  const [transactions, setTransactions] = useState<IStoreCreditTransaction[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { data: walletAmount } = useSWR(
    userId ? PATH_NAME.API_ADMIN_USER_GET_USER_WALLET_INFO(userId) : null,
    () => getUserWalletInfo(userId)
  );
  
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        const data: StoreCreditResponse = await getStoreCreditTransactionByUserIdAPI(userId);

        const sortedTransactions = data.result.items.sort((a, b) => 
          dayjs(b.created_at).valueOf() - dayjs(a.created_at).valueOf()
        );

        setTransactions(sortedTransactions);
      } catch (error) {
        setError(`Failed to fetch store credit transactions for user ID ${userId}`);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [userId]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <TableContainer component={Paper}>
      <Card sx={{ p: 3, textAlign: 'center', backgroundColor: '#ffffff', borderRadius: 2, mb: 2 }}>
        <Typography variant="body1" color="textSecondary">User Wallet Amount</Typography>
        <Typography variant="h6" color="#4CAF50" fontWeight="bold">
          {walletAmount?.wallet_amount || 0}
        </Typography>
        <Typography variant="body2" color="textSecondary">Points</Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
          Credit Card Linked: {walletAmount?.has_credit_card ? '✅' : '❌'}
        </Typography>
      </Card>

      <Table sx={{ minWidth: 650 }} size="small" aria-label="store credit transactions table">
        <TableHead>
          <TableRow>
            <TableCell>Transaction ID</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Order ID</TableCell>
            <TableCell align="right">Created At</TableCell>
            <TableCell align="right">Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions && transactions.length > 0 ? (
            transactions.map((transaction) => (
              <TableRow key={transaction.id}>
                <TableCell>{transaction.id}</TableCell>
                <TableCell align="right">{transaction.type}</TableCell>
                <TableCell align="right">{transaction.amount}</TableCell>
                <TableCell align="right">{transaction.order_id}</TableCell>
                <TableCell align="right">{dayjs(transaction.created_at).format('DD-MM-YYYY HH:mm')}</TableCell>
                <TableCell align="right">{transaction.description || 'N/A'}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No store credit transactions found for this user.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StoreCreditTransaction;
